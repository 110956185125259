import Swiper, {
	Navigation,
	Autoplay,
	Pagination,
	Parallax,
	EffectFade,
	Lazy,
} from 'swiper';
// import Swiper styles
import 'swiper/swiper-bundle.css';
import smoothscroll from 'smoothscroll-polyfill';

const scrollToSection = () => {
	smoothscroll.polyfill();
	window.__forceSmoothScrollPolyfill__ = true;
	const links = document.querySelectorAll('.nav-links a');

	for (const link of links) {
		link.addEventListener('click', clickHandler);
	}

	function clickHandler(e) {
		e.preventDefault();
		const href = this.getAttribute('href');
		const offsetTop = document.querySelector(href).offsetTop;

		scroll({
			top: offsetTop,
			behavior: 'smooth',
		});
	}
};

scrollToSection();

document.addEventListener('DOMContentLoaded', () => {
	Swiper.use([ Navigation, Autoplay, Pagination, Parallax, EffectFade, Lazy ]);

	const mySwiper = new Swiper('.mySwiper', {
        autoplay: {
            delay: 2500,
            disableOnInteraction: false,
        },
		pagination: {
			el: '.swiper-pagination',
			type: 'progressbar',
		},
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		},
	});
});
var html = document.documentElement;
var body = document.body;

var scroller = {
  target: document.querySelector("#scroll-container"),
  ease: 0.05, // <= scroll speed
  endY: 0,
  y: 0,
  resizeRequest: 1,
  scrollRequest: 0,
};

var requestId = null;

TweenLite.set(scroller.target, {
  rotation: 0.01,
  force3D: true
});

window.addEventListener("load", onLoad);

function onLoad() {    
  updateScroller();  
  window.focus();
  window.addEventListener("resize", onResize);
  document.addEventListener("scroll", onScroll); 
}

function updateScroller() {
  
  var resized = scroller.resizeRequest > 0;
    
  if (resized) {    
    var height = scroller.target.clientHeight;
    body.style.height = height + "px";
    scroller.resizeRequest = 0;
  }
      
  var scrollY = window.pageYOffset || html.scrollTop || body.scrollTop || 0;

  scroller.endY = scrollY;
  scroller.y += (scrollY - scroller.y) * scroller.ease;

  if (Math.abs(scrollY - scroller.y) < 0.05 || resized) {
    scroller.y = scrollY;
    scroller.scrollRequest = 0;
  }
  
  TweenLite.set(scroller.target, { 
    y: -scroller.y 
  });
  
  requestId = scroller.scrollRequest > 0 ? requestAnimationFrame(updateScroller) : null;
}

function onScroll() {
  scroller.scrollRequest++;
  if (!requestId) {
    requestId = requestAnimationFrame(updateScroller);
  }
}

function onResize() {
  scroller.resizeRequest++;
  if (!requestId) {
    requestId = requestAnimationFrame(updateScroller);
  }
}

var swiper = new Swiper(".mySwiper", {
    pagination: {
      el: ".swiper-pagination",
      type: "progressbar",
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  });

  const nameEl = document.querySelector("#name");
  const emailEl = document.querySelector("#email");
  const companyNameEl = document.querySelector("#company-name");
  const messageEl = document.querySelector("#message");
  
  const form = document.querySelector("#submit-form");
  
  function checkValidations() {
    let letters = /^[a-zA-Z\s]*$/;
    const name = nameEl.value.trim();
    const email = emailEl.value.trim();
    const companyName = companyNameEl.value.trim();
    const message = messageEl.value.trim();
    if (name === "") {
       document.querySelector(".name-error").classList.add("error");
        document.querySelector(".name-error").innerText =
          "Wypełnij to pole";
    } else {
      if (!letters.test(name)) {
        document.querySelector(".name-error").classList.add("error");
        document.querySelector(".name-error").innerText =
          "Prosimy, wpisz tylko litery";
      } else {
        
      }
    }
    if (email === "") {
       document.querySelector(".name-error").classList.add("error");
        document.querySelector(".name-error").innerText =
          "Prosimy, wypełnij to pole";
    } else {
      if (!letters.test(name)) {
        document.querySelector(".name-error").classList.add("error");
        document.querySelector(".name-error").innerText =
          "Prosimy, wpisz tylko litery";
      } else {
        
      }
    }
  }
  
  function reset() {
    nameEl = "";
    emailEl = "";
    messageEl = "";
    document.querySelector(".name-error").innerText = "";
  }